<template>
  <div class="scan-result-container">
    <button class="down-btn" @click="onDownApp">立即下载</button>

    <!-- 当页面在微信内部时 打开浏览器 -->
    <el-drawer v-model="showLeaderFlag" width="100%" direction="ttb" :show-close="false" :close-on-click-modal="false"
      custom-class="leader-dialog">
      <div class="open-browser-wrapper" @click.stop>
        <div class="arrow-icon">
          <svg t="1610539426235" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="8453" width="200" height="200">
            <path
              d="M946.176 198.007L931.091 0 810.309 157.646l54.946 16.384c-5.794 20.88-21.679 72.63-51.15 133.67-35.166 72.629-97.605 173.63-194.91 242.163-41.26 29.072-100.202 51.55-159.144 61.24 39.96-93.508 44.656-174.23 12.088-224.88-26.774-41.76-76.026-61.64-146.557-59.242-53.648 1.798-108.794 44.956-131.272 102.6-20.78 53.447-9.29 107.095 31.47 147.256 45.455 44.656 115.786 65.835 198.606 59.941-28.073 55.246-86.516 143.66-206.299 254.552-72.929 67.534-134.768 111.89-135.468 112.29-4.795 3.397-5.894 10.09-2.497 14.885 2.098 2.898 5.394 4.496 8.691 4.496 2.098 0 4.296-0.6 6.194-1.998 0.6-0.4 63.438-45.356 137.366-113.789 123.979-114.688 183.82-206.398 212.193-263.143a224.021 224.021 0 0 0 4.795-9.89c66.435-8.792 134.868-33.867 181.923-66.935 101.3-71.23 165.838-175.728 202.102-250.855 30.27-62.539 46.155-114.488 52.25-136.367l60.54 17.983zM435.076 614.4c-1.4 0.1-2.698 0.3-4.097 0.4-80.222 7.392-147.855-11.49-190.314-53.248-47.154-46.255-36.864-97.705-26.574-124.18 19.48-50.05 66.635-87.514 112.09-89.112 3.597-0.1 7.094-0.2 10.49-0.2 56.745 0 96.206 16.684 117.286 49.652 29.67 45.955 22.378 125.977-18.882 216.688z"
              p-id="8454" fill="#ffffff"></path>
          </svg>
        </div>
        <span class="help-box">请点击右上角选择在浏览器打开</span>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import urlib from 'url'
import UA from 'ua-device'

export default {
  name: '',

  components: {},

  setup () {
    const showLeaderFlag = ref(false)

    const ua = navigator.userAgent.toLowerCase()
    const handleUaQuerys = userAgent => {
      const uaParser = ua => ua ? new UA(ua) : {}
      return uaParser(userAgent).os.name
    }

    const appName = handleUaQuerys(ua)

    const iosUrl = 'https://apps.apple.com/cn/app/%E6%9D%BE%E6%9E%9Ccam/id1609007241'
    const androidUrl = 'https://fcwl-datacenter.oss-cn-beijing.aliyuncs.com/pinecone_cam/app-release_sign.apk?Expires=1978328800&OSSAccessKeyId=LTAI5t7aaJJ9mcMc3p3vPTUv&Signature=HmQza%2FYcYpeKczB9XTIjx7af2E8%3D'

    var isAndroid = ua.indexOf('android') > -1 || ua.indexOf('linux') > -1;
    var isIOS = appName === 'iOS'

    const autoDown = () => {
      if (isAndroid) {
        window.location.href = androidUrl
        window.onload()
      } else if (isIOS) {
        window.location.href = iosUrl
      }

      setTimeout(() => {
        // 跳转后关闭当前扫码页面
        window.location.href = 'about:blank'

        window.close()
      }, isAndroid ? 1000 : 200);
    }

    const onDownApp = () => {
      // 是否是微信内置浏览器打开
      if (ua.match(/MicroMessenger/i) == 'micromessenger' && isAndroid) {
        showLeaderFlag.value = true
      } else {
        autoDown()
      }
    }

    onMounted(() => {
      if (!(ua.match(/MicroMessenger/i) == 'micromessenger' && isAndroid)) {
        autoDown()
      }
    })
    return {
      showLeaderFlag,
      onDownApp
    }
  }
}
</script>

<style lang="scss" scoped>
.scan-result-container {
  background-image: url('../assets/start-bg.png');
  background-repeat: round;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .down-btn {
    background: #f9c13b;
    height: 56px;
    width: 150px;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
  }
}
</style>

<style lang="scss">
.leader-dialog {
  background: transparent;
  box-shadow: unset;
  height: 50% !important;

  .el-drawer__header {
    margin-bottom: 0;
  }

  .el-drawer__body {
    padding: 0;
  }

  .open-browser-wrapper {
    .arrow-icon {
      text-align: right;
      padding: 5px 10px;

      svg {
        width: 100px;
        height: 100px;
      }
    }

    .help-box {
      border: 2px dashed #f1f1f1;
      border-radius: 43px;
      padding: 20px 15px;
      color: #f1f1f1;
      display: inline-block;
      width: 140px;
    }
  }
}
</style>
